import { useQueryFetch } from "@/hooks/api/useQueryFetch";
import { useGetHighestAprVault } from "@/hooks/vault/useVault";
import {
  AppNumber,
  LARGE_NUMBER,
} from "@/lib/providers/math/app-number.provider";
import { LayoutSectionCard } from "@/ui/Components/LayoutSectionCard";
import {
  Button,
  Flex,
  Text,
  Link,
  FlexProps,
  Grid,
  GridItem,
  Box,
  Image,
  Skeleton,
} from "@chakra-ui/react";
import { route } from "nextjs-routes";
import { FC } from "react";

type StaticInfoItem = { title: string; value: string; isLoading: boolean };
const StaticInfoItem: FC<StaticInfoItem & FlexProps> = ({
  title,
  value,
  isLoading,
  ...props
}) => (
  <Flex
    flexDirection="column"
    borderRadius="16px"
    justifyContent="flex-end"
    alignItems="center"
    gap="88px"
    padding="48px 50px 24px 50px;"
    height="240px"
    {...props}
  >
    <Skeleton isLoaded={!isLoading}>
      <Text size="5xl" fontWeight={700}>
        {value}
      </Text>
    </Skeleton>
    <Text size="xl" fontWeight={700}>
      {title}
    </Text>
  </Flex>
);

export const AmplifyVaults: FC = () => {
  const queryFetch = useQueryFetch();
  const {
    data: tvl,
    isFetching,
    isPending,
  } = queryFetch("vault_tvl", { queryOptions: { refetchInterval: 100000 } });
  const highestAprVault = useGetHighestAprVault();

  const isLoading = isFetching || isPending;

  return (
    <LayoutSectionCard>
      <Box position="absolute" zIndex={1} left={0} top={0} w="full">
        <Image
          src="/images/decor.png"
          alt="Amplify Vaults"
          objectFit="cover"
          w="full"
          h="full"
        />
      </Box>
      <Flex
        flexDirection="column"
        position="relative"
        zIndex={2}
        w="full"
        gap="60px"
      >
        <Flex flexDirection="column" gap="28px">
          <Text textAlign="center" size="7xl" as="h1">
            Amplify Vaults
          </Text>
          <Text
            textAlign="center"
            size="3xl"
            w={{ xl: "850px", lg: "810px" }}
            mx="auto"
            as="h2"
          >
            Boosting your decentralized finance returns through strategic asset
            multiplication and advanced yield optimization techniques.
          </Text>
          <Link href={route({ pathname: "/vaults" })} mx="auto">
            <Button colorScheme="white" size="lg" width="180px">
              Launch app
            </Button>
          </Link>
          <Flex justifyContent="center">
            <Text size="xl">
              build on{" "}
              <Link
                href="https://www.sei.io/"
                target="_bank"
                textDecor="underline"
              >
                SEI Blockchain
              </Link>
            </Text>
          </Flex>
        </Flex>
        <Flex
          flexDirection="column"
          position="relative"
          mx="auto"
          width={{ base: "full", lg: "726px", xl: "850px" }}
        >
          <Grid
            templateColumns={{ base: "repeat(1, 1fr)", lg: "repeat(2, 1fr)" }}
            alignItems="center"
            rowGap="20px"
            columnGap="24px"
          >
            <GridItem colSpan={1}>
              <StaticInfoItem
                bgColor="decorative.02"
                title="Total Value Locked"
                value={`$${AppNumber.from(tvl ?? 0).getDisplayedString(LARGE_NUMBER.mm, 2)}`}
                isLoading={isLoading}
              />
            </GridItem>
            <GridItem colSpan={1}>
              <StaticInfoItem
                bgColor="decorative.04"
                title="Highest APY"
                value={`${((highestAprVault?.apr?.forwardAPR?.composite?.v3OracleStratRatioAPY ?? 0) * 100).toFixed(2)}%`}
                isLoading={isLoading}
              />
            </GridItem>
          </Grid>
        </Flex>
      </Flex>
    </LayoutSectionCard>
  );
};
