import type { Route } from "nextjs-routes";

type OGPageType = "Home Page" | "Root page" | "Regular page";

const OG_TYPE_DICT: Record<Route["pathname"], OGPageType> = {
  "/": "Home Page",
  "/vaults": "Regular page",
  "/vaults/[id]": "Regular page",
  "/press-kit": "Regular page",
};

export default function getPageOgType(pathname: Route["pathname"]) {
  return OG_TYPE_DICT[pathname];
}
