import Head from "next/head";
import React from "react";

import type { Route } from "nextjs-routes";

import * as metadata from "lib/metadata";
import { useVault } from "@/hooks/vault/useVault";

type Props = Route;

const BuildHead = (props: Props) => {
  const {
    title: pTitle,
    description,
    openGraph,
    thumbnail,
  } = metadata.generate(props);
  const { vaults } = useVault();
  const vault =
    Object.values(vaults)?.find((v) => v.address === props?.query?.id) || null;
  let title = pTitle;
  if (vault) {
    title = `PIT finance | ${vault?.token?.name} Vault`;
  }

  const favIconContent = (
    <>
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/assets/icons/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/assets/icons/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/assets/icons/favicon-16x16.png"
      />
      <link rel="manifest" href="/assets/icons/site.webmanifest" />
      <link
        rel="mask-icon"
        href="/assets/icons/safari-pinned-tab.svg"
        color="#5bbad5"
      />
    </>
  );

  const ogContent = (
    <>
      <meta property="og:title" content={title} />
      <meta property="og:image" content={thumbnail} />
      <meta property="og:description" content={description} />
      <meta property="og:type" content="website" />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      <meta property="og:image:type" content="image/png" />
      <meta property="og:image:alt" content="Pit Finance" />
      <meta property="og:url" content="https://pit.finance" />
    </>
  );

  const twitterContent = (
    <>
      <meta name="twitter:title" content={title} />
      <meta name="twitter:image" content={thumbnail} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:site" content="@pit.finance" />
      <meta name="twitter:creator" content="@pit.finance" />
      <meta name="twitter:card" content="summary_large_image" />
    </>
  );

  return (
    <Head>
      <title>{title}</title>
      <meta name="description" content={description} />

      {/* OG TAGS */}
      <meta property="og:title" content={openGraph.title} />
      {openGraph.description && (
        <meta property="og:description" content={openGraph.description} />
      )}
      {favIconContent}
      {ogContent}
      {twitterContent}
    </Head>
  );
};

export default BuildHead;
